<template>
  <div id="users">
    <!--<b-table striped hover :items="users.users" />-->
    <div style="margin-bottom: 50px">
      <b-card no-body>
        <b-card-header class="pb-50">
          <b-row no-gutters class="w-100">
            <b-col>
              <h5>{{msg("Filters")}}</h5>
            </b-col>
            <b-col>
              <div class="d-flex justify-content-end">
                <b-link style="color: inherit" >
                  <feather-icon icon="RefreshCcwIcon" size="16" />
                </b-link>
              </div>
            </b-col>
          </b-row>
        </b-card-header>
        <b-card-body>
          <b-row>
            <!--Id-->
            <b-col cols="12" md="4" class="mb-md-0 mb-2">
              <label>{{msg("ID")}}</label>
              <b-form-input
                  v-model="filter.id"
                  class="d-inline-block mr-1"
              />
            </b-col>

            <!-- First name-->
            <b-col cols="12" md="4" class="mb-md-0 mb-2">
              <label>{{msg("First name")}}</label>
              <b-form-input
                  v-model="filter.firstname"
                  class="d-inline-block mr-1"
              />
            </b-col>
            <!-- Last name-->
            <b-col cols="12" md="4" class="mb-md-0 mb-2">
              <label>{{msg("Last name")}}</label>
              <b-form-input
                  v-model="filter.lastname"
                  class="d-inline-block mr-1"
              />
            </b-col>
            <!--User name-->
            <b-col cols="12" md="4" class="mb-md-0 mb-2">
              <label>{{msg("Username")}}</label>
              <b-form-input
                  v-model="filter.username"
                  class="d-inline-block mr-1"
              />
            </b-col>
            <!-- Email-->
            <b-col cols="12" md="4" class="mb-md-0 mb-2">
              <label>{{msg("Email")}}</label>
              <b-form-input
                  v-model="filter.email"
                  class="d-inline-block mr-1"
              />
            </b-col>
            <!--Role-->
            <b-col cols="12" md="4" class="mb-md-0 mb-2">
              <label>{{ msg('Role') }}</label>
              <v-select
                  :dir="isRTL ? 'rtl' : 'ltr'"
                  v-model="filter.roleid"
                  :options="getRolesAsArray"
                  class="w-100"
                  label="name"
                  :reduce="(role) => role.id"
                  :clearable="false"
              />
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
      <b-card no-body>
        <!--Card Content-->
        <div v-if="loaded">
          <div class="m-2">

            <!-- Table Top -->
            <b-row>

              <!-- Per Page -->
              <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                <label>{{msg('Show')}}</label>
                <v-select
                    v-model="page.amountPerPage"
                    :dir="isRTL ? 'rtl' : 'ltr'"
                    :options="[10, 25, 50, 100]"
                    :clearable="false"
                    class="per-page-selector d-inline-block mx-50"
                />
                <label>{{msg('entries')}}</label>
              </b-col>

              <!-- Search -->
              <b-col cols="12" md="6">
                <div class="d-flex align-items-center justify-content-end">
                  <b-form-input v-model="filter.search" class="d-inline-block mr-1" placeholder="Search..."/>
                  <b-button variant="primary" @click="onUserSelect(0)" >
                    <span class="text-nowrap">{{msg('New user')}}</span>
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </div>
          <!--Table-->
          <table v-if="loaded" class="table b-table table-hover" v-bind:class="{'table-striped':loaded}">
            <thead>
            <tr>
              <!--Table header-->
              <!-- roleId: 1
               lang: 1
               orgId: 1
               password: "123"
               username: "a"-->
              <th>{{ msg('ID') }}</th>
              <th>{{ msg('First name') }}</th>
              <th>{{ msg('Last name') }}</th>
              <th>{{ msg('Role') }}</th>
              <th>{{ msg('Username') }}</th>
              <th>{{ msg('Phone') }}</th>
              <th>{{ msg('Email') }}</th>
              <th>{{ msg('BusinessUnit') }}</th>
              <th>{{ msg('Active') }}</th>
              <!--Options in header-->

            </tr>
            </thead>
            <tbody>
            <!--Table-->
            <template v-if="loaded">
              <tr v-for="user in users" v-bind:key="user.id">
                <td @click="onUserSelect(user.id);dialogs.user=true">
                  {{ user.id }}
                </td>
                <td @click="onUserSelect(user.id);dialogs.user=true">
                  {{ user.firstname }}
                </td>
                <td @click="onUserSelect(user.id);dialogs.user=true">
                  {{ user.lastname }}
                </td>
                <td @click="onUserSelect(user.id);dialogs.user=true">
                  {{ getRoleName(user.roleId) }}
                </td>
                <td @click="onUserSelect(user.id);dialogs.user=true">
                  {{ user.username }}
                </td>
                <td @click="onUserSelect(user.id);dialogs.user=true">
                  {{ user.phone }}
                </td>
                <td @click="onUserSelect(user.id);dialogs.user=true">
                  {{ user.email }}
                </td>
                <td @click="onUserSelect(user.id);dialogs.user=true">
                  {{ getAllBuNamesDelimited(user) }}
                </td>
                <td @click="onUserSelect(user.id);dialogs.user=true">
                  {{ msg(user.active ? 'Yes' : 'No') }}
                </td>
              </tr>
            </template>
            <!--Loading-->
            <template v-else>
              <tr class="table-loading">
                <td colspan="9">
                  <p>
                    <b-spinner label="Spinning"/>
                    {{ msg('loading') }}
                  </p>
                </td>
              </tr>
            </template>
            </tbody>
          </table>
           <!--Table Paginator-->
           <div class="paginator text-center">
               <!--Page number-->
               <div class="text-right" style="display: inline-block">
                   ({{((page.number-1)*page.amountPerPage)+1}} of {{page.total}})
               </div>
               <!--Paginator-->
               <div class="ml-2" style="display: inline-block ">
                   <b-pagination
                       :disabled="!loaded"
                       limit="100"
                       :per-page="page.amountPerPage"
                       :current-page="page.number"
                       :total-rows="page.total"
                       align="center"
                       @input="onPageChange"
                   />
               </div>
           </div>
          <!--Components-->
          <!--Edit user Modal -->
          <b-modal no-fade v-model="dialogs.user" size="xl" ref="user-modal" id="user-modal" :hide-footer="true"  no-close-on-backdrop centered>
            <user-panel ref="userPanel" :id="selected" @submitted="onSubmitted"/>
            <div class="text-center" style="justify-content:center">
              <b-button variant="outline-primary" class="btn-dialog" @click="submitUser">{{ msg('Save') }}
              </b-button>
            </div>
          </b-modal>
        </div>
        <div class="card-body" v-else>
          <div style="min-height: 300px;text-align: center">
            <div style="margin-top: 20%">
              <b-spinner label="Spinning"/>
              {{ msg('loading') }}
            </div>
          </div>
        </div>
      </b-card>
    </div>
  </div>
</template>


<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import UserPanel from '../../components/panels/user/userPanel'

export default {
  name: 'users',
  components: { UserPanel, },
  data() {
    return {

      selected: 0,
      loaded: false,
      filter: {},

      users:[],


      listeners: {
        refresh: () => {
        }
      },
      page: {
        amountPerPage: 200,
        total: 1,
        number: 1,
        columns: []
      },
      //Dialogs to show
      dialogs: { user: false }
    }
  },
  watch: {
    filter: {
      deep: true,
      handler() {
        this.refresh()
      }
    },
  },
  computed: {
    ...mapGetters('users', ['getRoleName', 'getRolesAsArray']),
    ...mapGetters('permissions', ['canReadPermission']),

  },
  created() {
    this.load()
    this.$root.$on('crm::branch-updated', (this.listeners.refresh = () => {
      this.refresh();
    }))
    this.$root.$on('crm::user-table::refresh', this.listeners.refresh)

  },
  beforeDestroy() {
    this.loaded = false
    this.$root.$off('crm::branch-updated', this.listeners.refresh)
    this.$root.$off('crm::user-table::refresh', this.listeners.refresh)
  },
  methods: {
    ...mapActions('users', ['getData']),
    ...mapActions('users', {refreshUsers:'refresh'}),
    load() {
      let $this = this;
      this.getData().then(this.refresh)
    },
    refresh(){
      let $this = this;
      this.refreshUsers({
        filter: this.filter,
        amountPerPage:$this.page.amountPerPage,
        page:this.page.number,
      }).then(data=>{
        $this.users = data['Users']
        $this.page.number = data['Page']
        $this.loaded = true
        $this.loaded = true
      })
    },
    getAllBuNamesDelimited(user) {

      console.log('getAllBuNamesDelimited', user)
      if (user.allbusinessUnits == null) {
        return '1'
      }

      let $this = this
      let temp = user.allbusinessUnits.map((c) => $this.getBranchName(c))
      return temp.join()

    },
    onUserSelect(id) {
      this.selected = id;
      this.dialogs.user=true
    },
    onPageChange(val) {
      this.selectedRows = []
      this.refresh({ filter: this.filter })
    },
    filterUpdate() {
      this.selectedRows = []
      this.refresh({ filter: this.filter })

    },
    submitUser() {
      this.$root.$emit('crm::save-user')
      /* setTimeout(() =>
           this.refresh({filter: this.filter}), 700
       )*/
    },
    onSubmitted(a) {
      if (a.status == true && a.id != null) {
        this.selected = a.id
      }

      this.refresh({ filter: this.filter })

    }

  }
}
</script>

<style>


</style>
