<template>
  <div class="userPanel" style="height: auto">
    <b-tabs class="tabs-title">
      <b-tab :title="msg('Personal details')" active>
        <div class="mt-2" v-if="loaded">

          <validation-observer ref="refFormObserver">
            <!-- Header: Personal Info -->
            <div class="d-flex">
              <feather-icon icon="UserIcon" size="19"/>
              <h4 class="mb-0 ml-50">
                {{ msg('Personal Information') }}
              </h4>
            </div>


            <!-- Form: Personal Info Form -->
            <b-row class="mt-1">
              <!-- Field: First name -->
              <b-col cols="12" md="6" lg="4">
                <b-form-group :label="msg('First name')" label-for="user-firstname">
                  <validation-provider #default="{ errors }" :name="msg('First name')" rules="required">
                    <b-form-input id="user-firstname" v-model="user.firstname" :state="errors.length > 0 ? false:null"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- Field: Last name -->
              <b-col cols="12" md="6" lg="4">
                <b-form-group :label="msg('Last name')" label-for="user-lastname">
                  <b-form-input id="user-lastname" v-model="user.lastname"/>
                </b-form-group>
              </b-col>

              <!-- Field: Language -->
              <b-col cols="12" md="6" lg="4">
                <b-form-group :label="msg('Language')" label-for="user-language">
                  <v-select
                      :dir="isRTL ? 'rtl' : 'ltr'"
                      v-model="user.lang"
                      :options="allLanguage"
                      class="w-100"
                      label="language"
                      :reduce="(lang) => lang.code"
                  />
                </b-form-group>
              </b-col>

              <!-- Field: Document type -->
              <b-col cols="12" md="6" lg="4">
                <b-form-group :label="msg('Document type')" label-for="user-docType">
                  <v-select
                      :dir="isRTL ? 'rtl' : 'ltr'"
                      v-model="user.docType"
                      :options="[{value: 'id',label:msg('Document Id')},{value: 'id',label:msg('Passport')}]"
                      class="w-100"
                      :reduce="(type) => type.value"
                  />
                </b-form-group>
              </b-col>

              <!-- Field: Document number -->
              <b-col cols="12" md="6" lg="4">
                <b-form-group :label="msg('Document number')" label-for="user-docNum">
                  <b-form-input id="user-docNum" v-model="user.docNum"/>
                </b-form-group>
              </b-col>

              <!-- Field: Birth Date -->
              <b-col cols="12" md="6" lg="4">
                <b-form-group :label="msg('birthday')" label-for="user-birthday">
                  <VueCtkDateTimePicker :button-now-translation="msg('Now')" v-model="user.birthday"
                                        :locale="currentFormattedLang" :only-date="true" format="YYYY-MM-DD" formatted="ll" no-header auto-close>
                    <b-form-input id="user-birthday" v-model="user.birthday"/>
                  </VueCtkDateTimePicker>

                </b-form-group>
              </b-col>

              <!-- Field: Gender -->
              <b-col cols="12" md="6" lg="4">
                <b-form-group :label="msg('Gender')" label-for="user-gender" label-class="mb-1">
                  <b-form-radio-group
                      id="user-gender"
                      v-model="user.gender"
                      :options="[ { text: msg('Male'), value: 'm' }, { text: msg('Female'), value: 'f' }, ]"
                      value="male"
                  />
                </b-form-group>
              </b-col>

            </b-row>

            <!-- Header: Address Info -->
            <div class="d-flex mt-1">
              <feather-icon
                  icon="MapPinIcon"
                  size="19"
              />
              <h4 class="mb-0 ml-50">
                {{ msg('Address') }}
              </h4>
            </div>

            <!-- Form: Address Info Form -->
            <b-row class="mt-1">

              <!-- Field: Address-->
              <b-col cols="12" md="6" lg="4">
                <b-form-group :label="msg('Address')" label-for="user-address">
                  <b-form-input id="user-address" v-model="user.address"/>
                </b-form-group>
              </b-col>

              <!-- Field: Postcode (Zip) -->
              <b-col cols="12" md="6" lg="4">
                <b-form-group :label="msg('Postcode')" label-for="postcode">
                  <b-form-input id="postcode" v-model="user.zip" type="number"/>
                </b-form-group>
              </b-col>

              <!-- Field: City -->
              <b-col cols="12" md="6" lg="4">
                <b-form-group label="City" label-for="user-city">
                  <b-form-input id="user-city" v-model="user.city"/>
                </b-form-group>
              </b-col>


              <!-- Field: Country -->
              <b-col cols="12" md="6" lg="4">
                <b-form-group :label="msg('Country')" label-for="user-country">
                  <v-select
                      :dir="isRTL ? 'rtl' : 'ltr'"
                      v-model="user.country"
                      :options="allCountries"
                      class="w-100"
                      label="name"
                      :reduce="(lang) => lang.iso"
                      autocomplete="on"
                  />
                </b-form-group>
              </b-col>

              <b-col cols="12" md="6" lg="4">
                <b-form-group label="Email" label-for="user-email">
                  <b-form-input id="user-email" v-model="user.email"/>
                </b-form-group>
              </b-col>
            </b-row>

            <!-- Header: Administrative Info -->
            <div class="d-flex mt-1">
              <feather-icon icon="ArchiveIcon" size="19"/>
              <h4 class="mb-0 ml-50">
                {{ msg('Administrative') }}
              </h4>
            </div>

            <!-- Form: Administrative Form -->
            <b-row class="mt-1">
              <!-- Field: branch -->
              <b-col cols="12" md="6" lg="4">
                <b-form-group :label="msg('Branch')" label-for="user-branch">
                  <v-select
                      multiple
                      :dir="isRTL ? 'rtl' : 'ltr'"
                      v-model="user.allBranches"
                      :options="allBranches"
                      class="w-100"
                      label="name"
                      :reduce="(branch) => branch.id"
                      :clearable="false"
                  />
                </b-form-group>
              </b-col>

              <!-- Field: Creation Date -->
              <b-col cols="12" md="6" lg="4">
                <b-form-group :label="msg('Creation Date')" label-for="user-creationTime">
                  <VueCtkDateTimePicker :button-now-translation="msg('Now')" v-model="user.creationTime"
                                        :locale="currentFormattedLang" format="YYYY-MM-DD HH:mm" formatted="ll" no-header auto-close>
                    <b-form-input id="user-creationTime" v-model="user.creationTime" disabled/>
                  </VueCtkDateTimePicker>
                </b-form-group>
              </b-col>

              <!-- Field: Start work Date -->
              <b-col cols="12" md="6" lg="4">
                <b-form-group :label="msg('Start Work')" label-for="user-creationTime">
                  <VueCtkDateTimePicker :button-now-translation="msg('Now')" v-model="user.startWork"
                                        :locale="currentFormattedLang" format="YYYY-MM-DD HH:mm" formatted="ll" no-header auto-close>
                    <b-form-input id="user-creationTime" v-model="user.startWork"/>
                  </VueCtkDateTimePicker>
                </b-form-group>
              </b-col>

              <!-- Field: Address-->
              <b-col cols="12" md="6" lg="4">
                <b-form-group :label="msg('Internphone')" label-for="user-address">
                  <b-form-input id="user-address" v-model="user.internphone"/>
                </b-form-group>
              </b-col>

              <!-- Field: Status -->
              <b-col cols="12" md="6" lg="4">
                <b-form-group :label="msg('Status')" label-for="user-branch">
                  <v-select
                      :dir="isRTL ? 'rtl' : 'ltr'"
                      v-model="user.active"
                      :options="[{value:true,label:msg('Active')},{value:false,label:msg('Not active')}]"
                      class="w-100"
                      label="label"
                      :reduce="(status) => status.value"
                      :clearable="false"
                  >
                    <template #option="{value,label}">
                      <i v-bind:class="{'fas fa-user-check':value,'fas fa-user-times':!value}"></i>
                      <span class="mr-1"></span>
                      {{ label }}
                    </template>
                    <template #selected-option="{value,label}">
                      <i v-bind:class="{'fas fa-user-check':value,'fas fa-user-times':!value}"></i>
                      <span class="mr-1"></span>
                      {{ label }}
                    </template>
                  </v-select>
                </b-form-group>
              </b-col>
            </b-row>

            <!-- Header: Access Info -->
            <div class="d-flex mt-1">
              <feather-icon icon="ArchiveIcon" size="19"/>
              <h4 class="mb-0 ml-50">
                {{ msg('Access') }}
              </h4>
            </div>

            <!-- Form: Access Form -->
            <b-row class="mt-1">
              <!-- Field: Role -->
              <b-col cols="12" md="6" lg="4">
                <validation-provider #default="{ errors }" :name="msg('Role')" rules="required|min:1">
                  <b-form-group :label="msg('Role')" label-for="role-branch">
                      <v-select
                          :dir="isRTL ? 'rtl' : 'ltr'"
                          v-model="user.roleId"
                          :options="Object.values(allRoles)"
                          class="w-100"
                          label="name"
                          :reduce="(role) => role.id"
                          :clearable="false"
                      />
                      <b-form-invalid-feedback errors.length > 0 ? false:null>
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- Field: username -->
              <b-col cols="12" md="6" lg="4">
                <b-form-group :label="msg('Username')" label-for="user-username">
                  <b-form-input id="user-username" v-model="user.username"/>
                </b-form-group>
              </b-col>

              <!-- Field: password -->
              <b-col cols="12" md="6" lg="4">
                <div class="d-flex">
                  <b-form-group :label="msg('New password')" label-for="user-password" class="w-100">
                    <div role="combobox" class="vs__dropdown-toggle">
                      <div class="vs__selected-options">
                        <input id="user-password" type="search" autocomplete="off" class="vs__search" v-model="user.password">
                      </div>
                      <div class="vs__actions">
                        <button type="button" :title="msg('Generate')" class="vs__clear" @click="generatePassword">
                          <feather-icon icon="RotateCwIcon" size="19"/>
                        </button>
                      </div>
                    </div>
                  </b-form-group>
                </div>
              </b-col>
            </b-row>
          </validation-observer>
        </div>
        <!----------------------------------------------------------------------->
        <div v-else-if="this.error != null">
          <b-alert show variant="danger"><i class="fas fa-exclamation-triangle mr-3"></i>
            <strong>{{ error }}</strong>
          </b-alert>
        </div>
        <div v-else>
          <div style="min-height: 300px;text-align: center">
            <div style="margin-top: 20%">
              <b-spinner label="Spinning"/>
              {{ msg('loading') }}
            </div>
          </div>
        </div>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>

import { mapActions, mapGetters, mapState } from 'vuex'

import { validation } from '../../../global/helper'

import { required, email, } from 'vuelidate/lib/validators'

export default {
  name: 'userPanel',
  props: {
    id: Number,
    isDashboard: {
      default: false,
      type: Boolean

    },
    disabled: {
      default: false,
      type: Boolean
    },
    showCompany: {
      default: true,
      type: Boolean
    },
    showAddress: {
      default: true,
      type: Boolean
    },
    userObj: {
      default: null,
      type: Object
    },
    maxHeight: {
      default: null,
      type: String
    },
    height: {
      default: null,
      type: String
    },
    value: {
      default: null,
      type: Object,
    }

  },
  data() {
    return {
      loaded: false,
      error: null,
      changeBu: false,
      user: {
        docCountry: null,
        country: null,
        docType: '',
        docNum: '',
        address: '',
        city: '',
        zip: '',
        roleId: 0,
        email: '',
        logolink: '',
        firstname: '',
        id: 0,
        cost: 0,
        lang: null,
        lastname: '',
        orgId: 0,
        phone: '',
        internphone: '',
        birthday: '',
        startWork: '',
        statusId: 1,
        username: '',
        allBranches: [],
        smtpList: [],
      },
      listeners: {
        submit: () => {
        }
      }
    }
  },
  watch: {
    userObj() {
      this.loadUserObject()
    },
    id: function () { // watch it
      this.onLoad()
      this.error = null
    },
    value: function () {
      this.onLoad()
      this.error = null
    }

  },
  computed: {
    ...mapGetters('translator', ['msg', 'currentFormattedLang']),
    ...mapGetters('user', ['myAdminStatus']),
    ...mapGetters('users', {
      allBranches: 'getAllBranches',
      mailsAlias: 'getMailsAlias',
      allRoles: 'getRolesAsArray'
    }),
    ...mapGetters('data', ['getAllLanguage', 'getAllBranches', 'getAllCountries', 'getAdminRoleId']),
    allCountries() {
      return Object.values(this.getAllCountries)
    },
    allLanguage() {
      return Object.values(this.getAllLanguage)
    },
    allBranches() {
      return Object.values(this.getAllBranches)
    },
    tabsStyles() {
      return `max-height: ${(this.maxHeight != null ? this.maxHeight : '550px')};height: ${(this.height != null ? this.height : 'auto')};`
    },
    userBirthday: {
      get() {
        return this.$moment(this.user.birthday)
            .format('DD/MM/YYYY')
      },
      set(value) {
        if (this.$moment(value, 'DD/MM/YYYY', true)
            .isValid()) {
          this.user.birthday = this.$moment(value, 'DD/MM/YYYY')
              .format('YYYY-MM-DD')
        }
      }
    },
    getRolesFiltered() {

      if (this.myAdminStatus) {
        return this.allRoles
      }

      for (var k in this.allRoles) {
        if ((k === this.getAdminRoleId)) {
          this.allRoles.delete(k)
        }
      }
      return this.allRoles

      // return Object.values(this.allRoles).filter(r => r.toLowerCase()!=='administrator').toObject("id")

    },

  },
  created() {

    this.loadUserObject()
    this.onLoad()
    this.$root.$on('crm::save-user', (this.listeners.submit = () => {
      this.submit()

    }))
  },
  beforeDestroy() {
    this.$root.$off('crm::save-user', this.listeners.submit)
  },
  methods: {
    ...mapActions('users', {
      _getUser: 'get',
      _updateUser: 'update',
      _register: 'register',
      _updateBusinessUnits: 'updateBusinessUnits'
    }),
    touch(field) {
      this.$v.$reset()
      //TODO: CHECK PROBLEM WITH VALIDATION
      if (true) return
      if (field == null) {
        this.touch()
        return
      }
      if (this.$v.user[field] == null) {
        return
      }
      this.$v.user[field].$touch()
    },
    loadUserObject() {
      if (this.userObj != null && this.userObj.id > 0) {
        this.user = this.userObj
        this.loaded = true
      } else {
        this.loaded = false
      }
    },
    resetUser() {
      this.user = {
        docCountry: null,
        country: null,
        docType: '',
        docNum: '',
        address: '',
        city: '',
        zip: '',
        roleId: 0,
        email: '',
        logolink: '',
        firstname: '',
        id: 0,
        cost: 0,
        lang: 'FR',
        lastname: '',
        orgId: 0,
        phone: '',
        internphone: '',
        birthday: '',
        startWork: '',
        statusId: 1,
        username: '',
        allBranches: [],
        smtpList: [],
      }
    },
    onLoad() {
      //In case that the prompt userObj is not null do not load
      if (this.userObj != null && this.userObj.id > 0) {
        return
      }
      this.loaded = false
      if (this.value != null) {
        this.resetUser()
        this.loaded = true
        return
      }

      if (this.id === 0 || typeof this.id !== 'number') {
        this.id = 0
        this.resetUser()
        this.loaded = true
      } else {
        this.loadUser()
      }

      this.changeBu = false
    },
    loadUser() {
      let $this = this
      $this.loaded = false
      this._getUser({ id: $this.id })
          .then((user) => {
            $this.user = user
            //Set page as loaded
            this.loaded = true
          }, (error) => {
            $this.error = error
          })
    },
    generatePassword(e) {
      if (e != null && typeof e.preventDefault === 'function') {
        e.preventDefault()
      }

      this.user.password = Math.random()
          .toString(36)
          .slice(-8)
      this.$forceUpdate()
    },
    createUser() {
      this._register({ user: this.user }).finally(this.refreshTable);
    },
    updateUser() {
      this._updateUser({ id: this.id, user: this.user }).finally(this.refreshTable);
    },
    submit() {
      return (this.id !== 0) ? this.updateUser() : this.createUser()
    },
    refreshTable() {
      this.$root.$emit("crm::user-table::refresh")
    }
  },

}
</script>

<style>
#userForm .md-list-item-content {
  padding: 0;
}

.md-layout-item {
  margin: 0 5px;
}

.md-list > .row {
  min-height: 60px;
}


</style>
